import React from 'react';
import './Header.css';

function Header() {
  return (
    <header>
      <h1>Buscador Aeronáutico</h1>
      <nav>
        <br/>Busca en documentos aeronáuticos como AIP I, DAN, DAR, DAP, y más.
        <br/><br/>
        <div className="links-de-interes">
          <strong>Links de interés:</strong>
          <a href="https://fedach.cl/">Federación Aérea de Chile (FEDACH)</a>,
          <br/><a href="https://aipchile.dgac.gob.cl/">Información de Vuelo Chile (IFIS)</a>, 
          <br/><a href="https://sipa.dgac.gob.cl/">Sistema de Información de Personal Aeronáutico (SIPA)</a>, 
          <br/><a href="https://www.dgac.gob.cl/">Dirección General de Aeronáutica Civil (DGAC)</a>, 
          <br/><a href="https://www.meteochile.gob.cl/PortalDMC-web/metaer/home.xhtml">Meteorología Aeronáutica (METEO CHILE)</a>
        </div>
        <br/>
        <div className="creditos">
          <strong>Creado y Desarrollado por:</strong> QFLY 2024
          <a href="mailto:contacto.qfly@gmail.com"> contacto.qfly@gmail.com</a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
