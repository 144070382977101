import React, { useState } from 'react';
import './SearchResults.css';

const SearchResults = ({ results, searchTime }) => {
  const [filter, setFilter] = useState('Todos'); // Set default filter to 'Todos'

  // Verificar si los resultados están vacíos o no son un array
  if (!results || !Array.isArray(results) || results.length === 0) {
    return <div className="centered-message">Intenta reformular tu pregunta para encontrar resultados.</div>;
  }

  // Lista de prefijos en orden alfabético, con "Todos" como la primera opción
  const prefixes = ['Todos', 'AIP', 'CA', 'DAC', 'DAN', 'DAP', 'DA ', 'DAR', 'DAR-Parte'];

  const filteredResults = results.filter(result => {
    if (filter === 'Todos') {
      return true;
    } else if (filter === 'DAR') {
      return /^DAR/.test(result.Title) && !/^DAR-Parte/.test(result.Title);
    } else {
      return result.Title.startsWith(filter);
    }
  });

  const displayedResults = filteredResults;

  return (
    <div className='search-results'>
      <p style={{ opacity: 0.8 }}>Exactamente {displayedResults.length} resultados ({searchTime} segundos)</p>

      <div className="filter-container">
        {prefixes.map((prefix, index) => (
          <button
            key={index}
            className={`filter-button ${filter === prefix ? 'active' : ''}`}
            onClick={() => setFilter(prefix)}
          >
            {prefix}
          </button>
        ))}
      </div>

      {displayedResults.map((result, index) => (
        <div key={index} className='result-item'>
          <h3>
            <a href={result.Link} target="_blank" rel="noopener noreferrer">
              {result.Title}
            </a>
          </h3>
          <p>
            Páginas Recomendadas: {result.Pages && Array.isArray(result.Pages) && result.Pages.length > 0
              ? result.Pages.map(page => page[0]).join(', ')
              : 'Intenta reformular tu pregunta para encontrar resultados.'}
          </p>
          {result.Pages && Array.isArray(result.Pages) && result.Pages.length > 0 && (
            <a href={result.Link} target="_blank" rel="noopener noreferrer" className="document-link">Ir al Documento Oficial</a>
          )}
        </div>
      ))}
    </div>
  );
};

export default SearchResults;

