// SearchBar.js

import React, { useState } from 'react';
import './SearchBar.css';  // Importar los estilos CSS

const SearchBar = ({ onSearch }) => {
  const [inputValue, setInputValue] = useState('');
  const [placeholder, setPlaceholder] = useState('Escribe tu consulta aquí');

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(inputValue);
  };

  const handleFocus = () => {
    setPlaceholder('');
  };

  const handleBlur = () => {
    if (inputValue === '') {
      setPlaceholder('Escribe tu consulta aquí');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="search-form">
      <input
        type='text'
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur} // Agregar el controlador de eventos onBlur
        placeholder={placeholder}
        maxLength={1000}  // Limitar la entrada a 1000 caracteres
      />
      <button type='submit'>Buscar</button>
    </form>
  );
};

export default SearchBar;
