// src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import SearchResults from './components/SearchResults';
import PopUp from './components/PopUp';
import './App.css';

function App() {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchTime, setSearchTime] = useState(0);

  const handleSearch = async (query) => {
    setLoading(true);
    const startTime = new Date().getTime();
    try {
      const response = await axios.post('https://serverec2.qfly.cl/consulta', { query_text: query });
      
      let data = response.data;
      if (!Array.isArray(data)) {
        data = [data]; // Si no es un array, conviértelo en un array con un solo elemento
      }

      console.log('Datos recibidos:', data); // Añade este log
      setResults(data);
      setHasSearched(true);
    } catch (error) {
      console.error(error);
    } finally {
      const endTime = new Date().getTime();
      const elapsedSeconds = (endTime - startTime) / 1000;
      setSearchTime(elapsedSeconds.toFixed(2));
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Buscador Aeronáutico";  // Establece el título cuando el componente se monte
  }, []);  // El array vacío asegura que este efecto solo se ejecute una vez

  return (
    <div>
      <PopUp />
      <Header />
      <SearchBar onSearch={handleSearch} />
      {loading ? (
        <div className="loading-circle"></div>
      ) : (
        hasSearched && <SearchResults results={results} searchTime={searchTime} />
      )}
    </div>
  );
}

export default App;





