// src/components/PopUp.js
import React, { useState } from 'react';
import './PopUp.css'; // Asegúrate de tener estilos CSS para el pop-up

const PopUp = () => {
  const [isVisible, setIsVisible] = useState(true);

  const closePopUp = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="popup">
        <div className="popup-inner">
          <h2>Tips para Buscar Eficazmente</h2>
          <ul>
            <li>Utiliza el formato <strong>[Código OACI] [Nombre Ciudad]</strong> para mejores resultados. Ejemplo: <strong>SCOV Ovalle</strong>.</li>
            <li>Las consultas son sensibles a mayúsculas. Ejemplo: asegúrate de escribir los códigos OACI en mayúsculas.</li>
            <li>No busques títulos de documentos directamente. En su lugar, busca conceptos relacionados. Esto te ayudará a encontrar el documento y la página específica donde se encuentra la respuesta a tu consulta.</li>
            <li>Reformular las preguntas puede ayudarte a encontrar mejores resultados, similar a como lo harías con un buscador tradicional.</li>
          </ul>
          <button onClick={closePopUp}>Cerrar</button>
        </div>
      </div>
    )
  );
};

export default PopUp;
